import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { TextHeader } from '../../../Controls/Digital/TextHeaders';

import imgAlex from './../Resources/Images/HeadShots/Alex.jpg'

const Teachers = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="teachers" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    About your Teacher
                </Fragment>
            } />

            <Row>
                <Col lg={{ size: 3, offset:2 }} className="text-center">
                    <img src={imgAlex} className="img-fluid" />
                </Col>
                <Col lg={{ size: 4 }} className="align-middle light-fontface">
                    <h3>Alex</h3>
                    <p>Hunting and food gathering is a big part of my life.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam. </p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,.</p>
                    <p> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam    </p>
                </Col>
            </Row>

            <Row>
                <Col lg={{ size: 7, offset: 2 }} className="align-middle light-fontface">
                    <p> quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate </p>
                </Col>
            </Row>

            <Row>
                <Col lg={{ size: 4, offset: 2 }} className="align-middle light-fontface">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <p>sed do eiusmod tempor incididunt ut labore et dolore</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p>
                    <p> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam    </p>
                </Col>
                <Col lg={{ size: 3 }} className="text-center">
                    <img src={imgAlex} className="img-fluid" />
                </Col>
            </Row>

        </Block>
    );
};

export default Teachers;
