import React, { Fragment, useState, useEffect } from "react";
import { Route } from "react-router";

import Common from "./Components/Shared/Common";

import { PaellaKing } from './Components/Brands/PK/Start';
import { BirdsMouth } from './Components/Brands/BM/Start';

import SpearfishingAcademy from './Components/Brands/ASA/Start';
import WildFoodMeister from './Components/Brands/WFM/Start';

import { RTech } from './Components/Brands/RTech/Start';
import { SiteNotAvailable } from './Components/Brands/SiteNotAvailable';
import KingFishCup from "./Components/Brands/KFC/Start";
import KingFishCupManage from "./Components/Brands/KFC/App/ManageDivers";
import KingFishCupAllFish from "./Components/Brands/KFC/App/ManageFish";
import { KingFishCupSignUp, KingFishCupSignOn, KingFishCupSignWeightIn, KingFishCupSignOff } from "./Components/Brands/KFC/App/DiverOperations";

const ApplicationRouting = (props) => {

    const [brandCode, setBrandCode] = useState("");

    useEffect(() => {
        Common.traceInit("Application Routing for Brand " + props.brandCode);
        setBrandCode(props.brandCode);

    }, [props.brandCode]);

    return ( 
        <Fragment>
            {brandCode === "" ? (
                <>
                    <Route exact path='/' component={SiteNotAvailable} />
                </>
            ) : (
                <>
                    {brandCode === "PK" && <Route exact path='/' component={PaellaKing} />}
                    {brandCode === "BM" && <Route exact path='/' component={BirdsMouth} />}
                    {brandCode === "ASA" && <Route exact path='/' component={SpearfishingAcademy} />}
                    {brandCode === "WFM" && <Route exact path='/' component={WildFoodMeister} />}
                    {brandCode === "RTech" && <Route exact path='/' component={RTech} />}

                    {brandCode === "KFC" && <Route exact path='/Register' component={KingFishCupSignUp} />}
                    {brandCode === "KFC" && <Route exact path='/SignOn' component={KingFishCupSignOn} />}
                    {brandCode === "KFC" && <Route exact path='/SignOff' component={KingFishCupSignOff} />}

                    {brandCode === "KFC" && <Route exact path='/' component={KingFishCup} />}
                    {brandCode === "KFC" && <Route exact path='/ManageDiversAdmin' component={KingFishCupManage} />}
                    {brandCode === "KFC" && <Route exact path='/ManageFishAdmin' component={KingFishCupAllFish} /> }
                    {brandCode === "KFC" && <Route exact path='/AddWeightIn' component={KingFishCupSignWeightIn} />}
                    {brandCode === "KFC" && <Route exact path='/AddWeightIn/:emailAddress' render={(props) => <KingFishCupSignWeightIn {...props} />} /> }

                </>
            )}
        </Fragment>
    );
};
export default ApplicationRouting;

//import KingFishCupManage from "./Components/Brands/KFC/App/ManageDivers";
//import KingFishCupAllFish from "./Components/Brands/KFC/App/ManageFish";
//import { KingFishCupSignUp, KingFishCupSignOn, KingFishCupSignWeightIn, KingFishCupSignOff } from "./Components/Brands/KFC/App/DiverOperations";

//{brandCode === "KFC" && <Route exact path='/Register' component={KingFishCupSignUp} />}
//{brandCode === "KFC" && <Route exact path='/SignOn' component={KingFishCupSignOn} />}
//{brandCode === "KFC" && <Route exact path='/SignOff' component={KingFishCupSignOff} />}
//{brandCode === "KFC" && <Route exact path='/AddWeightIn' component={KingFishCupSignWeightIn} />}
//{brandCode === "KFC" && <Route exact path='/AddWeightIn/:emailAddress' render={(props) => <KingFishCupSignWeightIn {...props} />} /> }