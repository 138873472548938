import React, { Fragment, useEffect, useState } from 'react';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { Row, Col } from 'reactstrap';

const StatementP2 = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="statementp1" isAlt={false}>

            <Row className="top-Inner no-gutters">
                <Col lg={{ size: 4, offset: 1 }}>

                    <TextHeader text={
                        <Fragment>
                            Attitudes
                        </Fragment>
                    } />

                    <p>
                        Wi Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                    </p>
                    <p>
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </p>
                </Col>

                <Col lg={{ size: 4, offset: 1 }}>

                    <TextHeader text={
                        <Fragment>
                            Teaching
                        </Fragment>
                    } />

                    <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </p>
                </Col>
            </Row>

        </Block>
    );
};

export default StatementP2;
