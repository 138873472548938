import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col } from 'reactstrap';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const Dates = (props) =>{

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="dates" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    Dates to remember
                </Fragment>
            } />

            <ContentAboutWrapper
                header="The information night – Thursday November 2nd from 5:30 pm"
                content={
                    <Fragment>
                        <p>
                            To kick off the biggest event on Sydney's spearfishing seasonal calendar we are hosting a social and information night to bring the community together under one roof to set the scene for the following weekends event.
                        </p>
                        <p>
                            The Kingfish Cup is an event built to bring our community together by way of a sustainable and ethically focused tournament with divers from all around Australia participating. Driven by inclusion, we encourage the aspiring noobie to the veteran diver of the tribe to participate, largely this is what spearfishing is all about - mateship, sharing the adventure, and, having respect for the fishery. The Kingfish Cup has been an outstanding success.
                        </p>
                        <p>
                            The Kingfish Cup has been an outstanding success in achieving all of this.
                        </p>
                        <p>
                            A Q&A panel with kingfish gurus Evann Leeson, Josh Ward and Artie Mensdorff the night's goal is to share experiences and knowledge around Sydney Spearfishing with a focus on the Kingfish species. Feedback from you has been requested on the science of this fishery, and we are giving this to you on the night, we have a very dedicated team who have been satellite tracking large Yellowtail Kingfish off our coastline and they are excited to be sharing their findings with us on the evening. An evening meal catered by the Paella King Rico, throw in  some sensational door prizes for those in attendance on the night, and to those who early bird register for the Cup, the night is shaping up to be a ripper.
                        </p>
                        <p>
                            This social event will be bigger than the Cup itself!
                        </p>
                    </Fragment>
                } />


            <ContentAboutWrapper
                header="Adreno Sydney Kingfish Cup  11-12th November"
                content={
                    <Fragment>
                    </Fragment>
                } />


            <ContentAboutWrapper
                header="The Presentation Thursday 16th November from 17:30 pm"
                content={
                    <Fragment>
                        Another fantastic social evening, where many of the entrants gather, again at Adreno Sydney Superstore, mingle and talk about the one that got away while enjoying some quality food while anticipating the results
                    </Fragment>
                } />

        </Block>
    );
}
export default Dates;

const ContentAboutWrapper = (props) => {
    return (
        <Row>
            <Col lg={{ site: 10, offset: 1 }} className="definition">
                <p>
                    <b>{props.header}</b>
                </p>
                <p>
                    {props.content}
                </p>
            </Col>
        </Row>
    );
}