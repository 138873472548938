import React, { Component, Fragment, useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import Common from '../../Shared/Common';

export class RTech extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <div>
                    RTech Landing Page
                </div>
                <div>
                    <MyForm />
                </div>
            </Fragment>
            
        );
    }
}

const MyFormModelHelper = {

    getModel: function () {
        return {
            firstName: '',
            email: '',
            phoneNumber: '',
            subject: '',
            message: ''
        };
    },

    setModel: function (model, property, value) {

    },

    M2(r) {
        return [];
    }

    //#endregion
};


const MyForm = (props) => {

    const [model, setModel] = useState(MyFormModelHelper.getModel());

    useEffect(() => {
        Common.traceInit("MyForm");
    });

    const handleChange = e => {
        setModel({ ...model, [e.target.name]: e.target.value });
        Common.trace("Changed Field {0} to {1}", [e.target.name, e.target.value]);
    }

    const rejectChanges = e => {

    }

    const sendMessage = () => {
        Common.log(model);
    };

    return (
        <Fragment>
            <Container>
                <Row>
                    <Col lg="2">
                        Hello there
                    </Col>
                    <Col lg="2">
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
