import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './../Resources/css/Partner.css';

import Block from '../../../Controls/Scafolding/Block';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { facebook, instagram, twitter } from '@fortawesome/free-solid-svg-icons'

import { faAlignCenter, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import Common from '../../../Shared/Common';

const Partners = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="partners" isAlt={true}>

            <Row>
                <Col lg="6">

                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <h3>Our Partners</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                            </Col>
                            <Col lg="4">
                            </Col>
                            <Col lg="4">
                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col lg="6">
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <h3>Find us on Social Media</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <a href="https://facebook.com/"><FontAwesomeIcon  /></a>
                            </Col>
                            <Col lg="4">
                                <a href="https://instagram.com/"><FontAwesomeIcon /></a>
                            </Col>
                            <Col lg="4">
                                <a href="https://twitter.com/"><FontAwesomeIcon /></a>
                            </Col>
                        </Row>
                    </Container>
                </Col>

            </Row>

            <Row>
                <Col lg="12" className="copyright text-center">
                    Copyright &copy; 2022 <span>Australia Spearfishing Academy</span>
                </Col>
            </Row>

        </Block>
    );
};

export default Partners;

