import React, { Fragment, useEffect, useState } from 'react';
import './../Resources/css/Banner.css'

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { Row, Col } from 'reactstrap';

const Banner = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="banner" isAlt={false} >
            <Row className="banner-text overlap moveUp p-4">
                <div className="col-4 text-center">
                    Hands on Experience
                </div>
                <div className="col-4 text-center">
                    With a Passionate teacher
                </div>
                <div className="col-4 text-center">
                    And Great support
                </div>
            </Row>
        </Block>
    );
};

export default Banner;