import Common from "../../../Shared/Common";

const KFCAPI = {

    getDivers: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/GetAll", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    getAllFish: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/GetAllFish", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    searchDiver: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/Search", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    signOn: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/SignOn", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    signOff: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/SignOff", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    addWeightIn: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/WeightIn", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

};
export default KFCAPI;
