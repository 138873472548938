import React, { useState } from 'react';

import './Resources/css/site.css';
import Common from '../../Shared/Common';

import AppWrapper from '../../Controls/Scafolding/AppWrapper';
import { NavMenu } from '../../Controls/NavMenu';
import Top from './Blocks/Top';
import About from './Blocks/About';
import Banner from './Blocks/Banner';
import StatementP1 from './Blocks/StatementP1';
import StatementP2 from './Blocks/StatementP2';
import Teachers from './Blocks/Teachers';
import Courses from './Blocks/Courses';
import Feedback from './Blocks/Feedback';
import ContactUs from './Blocks/ContactUs';
import { Gallery } from '../../Controls/Digital/Gallery';
import { TextHeader } from '../../Controls/Digital/TextHeaders';
import Block from '../../Controls/Scafolding/Block';
import Partners from './Blocks/Partners';

const WildFoodMeister = (props) => {

    const [menu] = useState({
        hasLogo: false,
        logoUrl: '',
        styles: {},

        text: 'Wild Food Meister',
        textSmallDisplay: 'Wild Food Meister',
        icon: "",

        items: [
            { linkLabel: 'Home', linkUrl: '#top' },
            { linkLabel: 'About', linkUrl: '#about' },
            { linkLabel: 'Your Teacher', linkUrl: '#teachers' },
            { linkLabel: 'Courses', linkUrl: '#courses' },
            { linkLabel: 'Feedback', linkUrl: '#Testimonials' },
            { linkLabel: 'Contact us', linkUrl: '#contactUs' },
            { linkLabel: 'Media', linkUrl: '#gallery' }
        ]
    });
    const [carouselImages] = useState(() => Common.ImportAllImagesInFolder(require.context('./Resources/Images/Carousel', false)));
    const [galleryImagesLeft] = useState(() => Common.ImportAllImagesInFolder(require.context('./Resources/Images/GalleryLeft', false)));
    const [galleryImagesRight] = useState(() => Common.ImportAllImagesInFolder(require.context('./Resources/Images/GalleryRight', false)));

    return (
        <AppWrapper wrapClass="wfm">
            <NavMenu menu={menu} addClass="Menu sticky-top" />
            <Top images={carouselImages} />
            <Banner />
            <About />
            <StatementP1 />
            <StatementP2 />
            <Teachers />
            <Courses />
            <Feedback />
            <ContactUs />
            <Block name="gallery" isFluid={true}>
                <TextHeader text="from our best adventures..." />
                <Gallery
                    imagesLeft={galleryImagesLeft}
                    imagesRight={galleryImagesRight}
                    placeholderSrc={null} />
            </Block>
            <Partners />
        </AppWrapper>
    );
}
export default WildFoodMeister;